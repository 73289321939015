import type {UserPermissionType} from '@prisma/client'
import {useProduct} from '../components/stack/product'
import {useAuth} from '../components/stack/auth'

const useHasPermission = (type: UserPermissionType) => {
  const {permissions = []} = useAuth()
  return permissions?.some(p => p.type === type)
}

export const useCanManageBoUsers = () => {
  // * Let user management open in all environments except prod
  const canManage = useHasPermission('BO_MANAGE_USERS')
  return process.env.AMBLER_STAGE !== 'prod' || canManage
}

export const useCanExportAnon = () => {
  const canExportAnon = useHasPermission('BO_EXPORT_ANONYMOUS')
  return canExportAnon
}

export const useCanExportFull = () => {
  const canExportFull = useHasPermission('BO_EXPORT_FULL')
  return canExportFull
}

export const useCanViewPatientIdentity = () => {
  const product = useProduct()
  const canViewPatientIdentity = useHasPermission('BO_PATIENT_IDENTITY_READ')

  return (
    canViewPatientIdentity ||
    product === 'A4D' ||
    // * For now, condition patient data view to permissions only in testing environments
    // todo https://app.shortcut.com/ambler/story/19315
    process.env.AMBLER_STAGE === 'prod' ||
    process.env.AMBLER_STAGE === 'demo'
  )
}
